import React, { useCallback } from 'react'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  TwitterAuthProvider,
  UserCredential,
  getAdditionalUserInfo,
} from 'firebase/auth'
import { Button, Icon } from '@blueprintjs/core'
import { TextLoop } from 'react-text-loop-next'
import { AppToaster } from '../components/AppToaster'

export const LoggedOut: React.FC = React.memo(() => {
  const auth = getAuth()

  // Callbacks
  const onSignIn = useCallback((userCredential: UserCredential) => {
    const additionalUserInfo = getAdditionalUserInfo(userCredential)
    if (additionalUserInfo?.isNewUser === false) {
      AppToaster.show({
        message: `Welcome back, ${
          userCredential.user.displayName?.split(' ')[0] || ''
        }! 👋`,
      })
    }
  }, [])
  const onAuthError = useCallback(({ message, code }) => {
    // TODO: better error handling, message isn't necessarily a string
    try {
      if (
        code !== 'auth/cancelled-popup-request' &&
        code !== 'auth/popup-closed-by-user'
      )
        AppToaster.show({ message: message as string, intent: 'danger' })
    } catch (e) {
      return
    }
  }, [])

  // Providers
  const signInWithGoogle = useCallback(() => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider).then(onSignIn).catch(onAuthError)
  }, [auth, onAuthError, onSignIn])
  const signInWithTwitter = useCallback(() => {
    const provider = new TwitterAuthProvider()
    signInWithPopup(auth, provider).then(onSignIn).catch(onAuthError)
  }, [auth, onAuthError, onSignIn])

  return (
    <div className="fixed flex-col items-center justify-center py-10 md:h-screen lg:static lg:flex lg:py-0">
      <div className="relative w-full max-w-screen-xl px-5">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/jeroenmakes/status/1530842623715549184"
          className="mb-3 inline-block bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-500 hover:text-orange-500"
        >
          V0.2 alpha
        </a>
        <h1 className="text-4xl font-medium tracking-tight text-slate-900 lg:max-w-md lg:text-6xl lg:tracking-tighter xl:max-w-none">
          Document your{' '}
          <span className="block lg:inline">
            <TextLoop>
              <div className="text-purple-500">database</div>
              <div className="text-cyan-500">API</div>
              <div className="text-sky-500">proposals</div>
              <div className="text-orange-500">types</div>
              <div className="text-rose-500">events</div>
            </TextLoop>
          </span>
        </h1>
        <div className="mt-4 text-xl text-slate-500 lg:text-2xl">
          Create sharable docs, free and super simple.
        </div>
        <div className="mt-4 border-l-2 border-orange-500 pl-4 text-base font-medium lg:hidden">
          WhatFields will support mobile soon, but doesn&apos;t right now.
          Sorry! It works well on your laptop though!
        </div>
        <a
          className="mt-6 inline-block"
          href="https://www.producthunt.com/posts/whatfields?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-whatfields"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=348293&theme=light"
            alt="WhatFields - Create&#0032;sharable&#0032;docs&#0044;&#0032;free&#0032;and&#0032;super&#0032;simple | Product Hunt"
            style={{ width: 250, height: 54 }}
            width="250"
            height="54"
          />
        </a>
        <div className="mt-8 hidden items-center space-y-4 lg:mt-16 lg:flex lg:space-y-0 lg:space-x-4">
          <div className="text-base text-slate-800">To continue:</div>
          <Button
            large
            onClick={signInWithGoogle}
            icon={
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
            }
          >
            Log in with Google
          </Button>
          <Button
            large
            onClick={signInWithTwitter}
            icon={
              <svg
                className="h-5 w-5 fill-current text-[#1D9BF0]"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 248 204"
              >
                <path
                  d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
		C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
		c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
		c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
		c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
		c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
                />
              </svg>
            }
          >
            Log in with Twitter
          </Button>
        </div>
      </div>
      <img src="/mobile.png" className="lg:hidden" alt="WhatFields" />

      <div className="pointer-events-none hidden lg:block">
        <div className="fixed -right-56 top-24 h-screen w-[600px] -rotate-12 transform bg-purple-500" />
        <div className="fixed -right-24 top-40 h-screen w-[420px] -rotate-[4deg] transform border border-purple-700 bg-white p-10 shadow-2xl">
          <div className="mb-6 rounded-full border p-2 px-4 font-medium text-slate-400">
            whatfields.com/whatapi
          </div>
          <div className="flex items-center space-x-4">
            <img
              alt="WhatFields logo"
              src="/favicons/android-chrome-192x192.png"
              height={76}
              width={76}
            />
            <h1 className="text-3xl">What API</h1>
          </div>
          <div className="mt-4 space-x-1 border-t border-b py-2">
            <Button text="Folder" icon="folder-new" minimal />
            <Button text="Page" icon="add" minimal />
          </div>
          <div className="mt-6 space-y-3">
            <div className="flex h-10 w-44 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
              <Icon icon="folder-open" className="text-slate-400" />
              <div>Endpoints</div>
            </div>
            <div className="ml-6 flex h-10 w-52 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
              <Icon icon="document" className="text-rose-400" />
              <div>Getting started</div>
            </div>
            <div className="ml-6 flex h-10 w-48 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
              <Icon icon="document" className="text-rose-400" />
              <div>Authentication</div>
            </div>
            <div className="ml-6 flex h-10 w-44 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
              <Icon icon="document" className="text-rose-400" />
              <div>Example call</div>
            </div>

            <div className="flex h-10 w-48 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
              <Icon icon="folder-close" className="text-slate-400" />
              <div>Object types</div>
            </div>
            <div className="flex h-10 w-56 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
              <Icon icon="folder-close" className="text-slate-400" />
              <div>Analytics events</div>
            </div>
            <div className="flex h-10 w-44 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
              <Icon icon="folder-close" className="text-slate-400" />
              <div>Database</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

LoggedOut.displayName = 'LoggedOut'
