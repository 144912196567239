import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { useCallback } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { Workspace } from '../types'
import { db } from '../pages/_app'

export function useWorkspace(id?: string) {
  const getWorkspace = useCallback(async () => {
    if (!id) return
    const docRef = doc(db, 'workspaces', id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as Workspace
    } else {
      throw Error('This workspace does not exist.')
    }
  }, [id])
  return useQuery(['workspace', id], getWorkspace, {
    enabled: !!id,
    useErrorBoundary: false,
  })
}

export function useWorkspaceBySlug(
  slug?: string
): UseQueryResult<void | Workspace> {
  const getWorkspace = useCallback(async () => {
    if (!slug) return
    const workspacesRef = collection(db, 'workspaces')
    const q = query(workspacesRef, where('slug', '==', slug))
    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) {
      // Try slug as ID
      const docRef = doc(db, 'workspaces', slug)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() } as Workspace
      } else {
        throw Error('This workspace does not exist.')
      }
    } else {
      const docSnap = querySnapshot.docs[0]
      return { id: docSnap.id, ...docSnap.data() } as Workspace
    }
  }, [slug])
  return useQuery(['workspace', slug], getWorkspace, {
    enabled: !!slug,
    useErrorBoundary: false,
  })
}
