import { IToaster, Position, Toaster } from '@blueprintjs/core'

/** Singleton toaster instance. Create separate instances for different options. */
const AppToasterFn =
  typeof window !== 'undefined' &&
  Toaster.create({
    className: 'toaster',
    position: Position.TOP,
  })

export const AppToaster = AppToasterFn as IToaster
