import { Spinner } from '@blueprintjs/core'
import React from 'react'

export const LoadingScreen: React.FC = React.memo(() => {
  return (
    <div className="flex h-screen items-center justify-center bg-slate-50">
      <Spinner size={48} intent="primary" />
    </div>
  )
})

LoadingScreen.displayName = 'LoadingScreen'
