import React, { useCallback, useEffect, useState } from 'react'
import { getAuth, signOut, User } from 'firebase/auth'
import { useRouter } from 'next/router'
import { Button, Icon, InputGroup } from '@blueprintjs/core'
import { FirebaseError } from 'firebase/app'
import getSlug from 'speakingurl'
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions'
import { AppToaster } from '../components/AppToaster'
import { useWorkspace } from '../data/useWorkspace'
import { LoadingScreen } from './LoadingScreen'

interface LoggedInProps {
  user?: User
}

type Setup = {
  error?: string
  success?: boolean
}

export const LoggedIn: React.FC<LoggedInProps> = React.memo(
  ({ user }: LoggedInProps) => {
    const { data, error } = useWorkspace(user?.uid)
    const [isLoading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [hasRedirected, setNoRedirect] = useState(false)
    const router = useRouter()
    const workspaceSlug = data?.slug
    const workspaceId = data?.id
    const setup = useCallback(async () => {
      if (!name) {
        return AppToaster.show({
          message: 'Please enter a name',
          intent: 'danger',
        })
      }
      setLoading(true)
      setNoRedirect(true)
      try {
        const slug = getSlug(name)
        const setup = httpsCallable(getFunctions(), 'setup')
        const res = (await setup({
          name: name,
          slug,
        })) as HttpsCallableResult<Setup>
        if (!res.data.success) {
          setLoading(false)
          AppToaster.show({ message: res.data.error || 'Something went wrong' })
        } else {
          void router.replace('/' + slug + '/welcome')
        }
      } catch (e) {
        setLoading(false)
        AppToaster.show({ message: (e as FirebaseError).message })
      }
    }, [name, router])

    // Workspace loaded
    useEffect(() => {
      if ((workspaceSlug || workspaceId) && !hasRedirected) {
        setNoRedirect(true)
        void router.replace(`/${workspaceSlug || workspaceId || ''}`)
      }
    }, [workspaceSlug, router, hasRedirected, workspaceId])

    if (error) {
      return (
        <div className="flex h-screen flex-col">
          <div className="flex-shrink-0 p-6 pb-0">
            <Button
              icon="chevron-left"
              small
              minimal
              className="text-xs"
              onClick={() => void signOut(getAuth())}
            >
              Wrong account?
            </Button>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              void setup()
            }}
            className="flex flex-1 flex-col items-center justify-center"
          >
            <div className="h1 text-2xl tracking-tighter text-purple-500">
              Welcome, {user?.displayName?.split(' ')[0] || ''}!
            </div>
            <div className="h1 text-4xl tracking-tighter">
              Let&apos;s create a new docs website
            </div>
            <InputGroup
              autoFocus
              className="mt-8 w-96"
              large
              disabled={isLoading}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Documentation name"
            />
            <div className="mt-3 w-96 text-xs">
              E.g. the name of your app or company. You can change it later!
            </div>
            <div className="mt-8 mb-8 w-96 rounded-full bg-slate-100 py-2 px-4 font-medium">
              <Icon icon="globe" color="gray" className="mr-2" />
              whatfields.com/
              <span className="text-purple-500">
                {getSlug(name) || 'your-name'}
              </span>
            </div>
            <Button loading={isLoading} type="submit" large intent="primary">
              Get started
            </Button>
          </form>
        </div>
      )
    }

    return <LoadingScreen />
  }
)

LoggedIn.displayName = 'LoggedIn'
