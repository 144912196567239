import type { NextPage } from 'next'
import { AuthError, getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { memo, useEffect, useState } from 'react'
import { FirebaseError } from 'firebase/app'
import { LoggedOut } from '../pageComponents/LoggedOut'
import { LoadingScreen } from '../pageComponents/LoadingScreen'
import { LoggedIn } from '../pageComponents/LoggedIn'
import { ErrorScreen } from '../pageComponents/ErrorScreen'

const Home: NextPage = memo(() => {
  const auth = getAuth()
  const [user, setUser] = useState<User | null | undefined>(undefined)
  const [error, setError] = useState<FirebaseError | AuthError | Error>()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setUser(user || null)
      },
      setError
    )
    return () => unsubscribe()
  }, [auth])

  if (error) return <ErrorScreen message={error.message} />
  if (user === undefined) return <LoadingScreen />

  return user ? <LoggedIn user={user} /> : <LoggedOut />
})

Home.displayName = 'Home'
export default Home
